
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { themes, Image } from 'mdx-deck'
import componentsEverywhereImg from './static/components-everywhere.jpg'
import frameworkImg from './static/framework.png'
import likeabosscatImg from './static/likeabosscat.jpg'
import danJesusContainerImg from './static/danjesus-container.png'
import ericImg from './static/eric.jpeg'
import ocpImg from './static/ocp.jpg'
import doesitscaleImg from './static/doesitscale.jpeg'
import itDoesntImg from './static/itdoesnt.jpg'
import reactcomponentsuiImg from './static/reactcomponentsui.png'
import finishingTouchImg from './static/finishingtouch.jpeg'
import spImg from './static/skutecznyprogramista.pl_z.png'
import thanksImg from './static/thanks.png'
import rocketImg from './static/rocketscience.jpg'
export const theme = { ...themes.prism,
  ...themes.swiss
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Footer = makeShortcode("Footer");
const Steps = makeShortcode("Steps");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Footer mdxType="Footer">
      <p>{`@kjendrzyca / skutecznyprogramista.pl`}</p>
    </Footer>
    <h1>{`The Zen of React 🧘`}</h1>
    <hr></hr>
    <h1>{`Expectations?`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Always work in progress.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Discussions are welcome (strong opinions, weakly held).`}</p>
        </li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`What is UI?`}</h1>
    <hr></hr>
    <h2>{`What is UI?`}</h2>
    <p>{`Backend - the things you don't see`}</p>
    <p>{`UI - the things you see`}</p>
    <hr></hr>
    <h2>{`What is UI?`}</h2>
    <p>{`Backend: `}<strong parentName="p">{`Objects`}</strong>{`, Classes, Modules, Functions`}</p>
    <p>{`Modern Frontend: `}<strong parentName="p">{`Components`}</strong></p>
    <hr></hr>
    <h2>{`What is UI?`}</h2>
    <p>{`Everything revolves around Components`}</p>
    <Image src={componentsEverywhereImg} style={{
      width: '45vw',
      height: '50vh'
    }} mdxType="Image" />
    <hr></hr>
    <h1>{`Rethinking Best Practices`}</h1>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/x7cQ3mrcKaY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
    <p><a parentName="p" {...{
        "href": "https://twitter.com/cowboy/status/339858717451362304"
      }}>{`https://twitter.com/cowboy/status/339858717451362304`}</a></p>
    <hr></hr>
    <h1>{`React is clean by design`}</h1>
    <Image src={frameworkImg} style={{
      width: '50vw',
      height: '40vh'
    }} mdxType="Image" />
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Segregate responsibilities by components, not technologies`}</em></strong></p>
    <p>{`Before: template.html, controller.js, style.css`}</p>
    <p>{`Now: component.jsx`}</p>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Divide reponsibilities based on what you see on the screen`}</em></strong></p>
    <Image src={reactcomponentsuiImg} style={{
      width: '30vw',
      height: '40vh'
    }} mdxType="Image" />
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Explicit dependencies - props`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Welcome(props) {
  return <h1>Hello, {props.name}</h1>;
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Explicit interface - prop types`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`Welcome.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`One way data flow`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<FormattedDate date={this.state.date} />

function FormattedDate(props) {
  return <h2>It is {props.date.toLocaleTimeString()}.</h2>;
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Explicit state updates`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`handleClick() {
  this.setState(state => ({
    isToggleOn: !state.isToggleOn
  }));
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Explicit event handlers`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`handleClick() {
  this.setState(state => ({
    isToggleOn: !state.isToggleOn
  }));
}

render() {
  return (
    <button onClick={this.handleClick}>
      {this.state.isToggleOn ? 'ON' : 'OFF'}
    </button>
  );
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`No inheritance, (functional) composition instead`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function SplitPane(props) {
  return (
    <div className="SplitPane">
      <div className="SplitPane-left">
        {props.left}
      </div>
      <div className="SplitPane-right">
        {props.right}
      </div>
    </div>
  );
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`No inheritance, (functional) composition instead`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function App() {
  return (
    <SplitPane
      left={
        <Contacts />
      }
      right={
        <Chat />
      } />
  );
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`No inheritance, (functional) composition instead`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Dialog(props) {
  return (
    <FancyBorder color="blue">
      <h1 className="Dialog-title">
        {props.title}
      </h1>
      <p className="Dialog-message">
        {props.message}
      </p>
    </FancyBorder>
  );
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`No inheritance, (functional) composition instead`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function WelcomeDialog() {
  return (
    <Dialog
      title="Welcome"
      message="Thank you for visiting our spacecraft!"
    />
  );
}
`}</code></pre>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`No inheritance, (functional) composition instead`}</em></strong></p>
    <blockquote>
      <p parentName="blockquote">{`"At Facebook, we use React in thousands of components,
and we haven’t found any use cases where we would recommend creating component inheritance hierarchies."`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`React is clean by design`}</h2>
    <p>{`🤯 `}<strong parentName="p">{`Tracking bugs in React app in less than 30 minutes (since 2015).`}</strong></p>
    <Image src={likeabosscatImg} style={{
      width: '40vw',
      height: '50vh'
    }} mdxType="Image" />
    <hr></hr>
    <h1>{`React patterns are clean by design`}</h1>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Containers and Presentational Components`}</em></strong></p>
    <Image src={danJesusContainerImg} style={{
      width: '40vw',
      height: '50vh'
    }} mdxType="Image" />
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"Update from 2019: I wrote this article a long time ago and my views have since evolved.`}</p>
      <p parentName="blockquote">{`In particular, `}<strong parentName="p">{`I don’t suggest splitting your components like this anymore.`}</strong></p>
      <p parentName="blockquote">{`If you find it natural in your codebase, this pattern can be handy. But I’ve seen it enforced without any necessity and with almost dogmatic fervor far too many times. The main reason I found it useful was because it let me separate complex stateful logic from other aspects of the component.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`Hooks let me do the same thing without an arbitrary division.`}</strong>{` This text is left intact for historical reasons but don’t take it too seriously."`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0"
      }}>{`https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0`}</a></p>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`Wrong Uncle Dan.`}</p>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`Containers can isolate state and side-effects from presentation components.`}</p>
    <p>{`--`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Instead of mocking for UI components, I separate components into display components and container components.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`The containers manage state and side-effects `}</strong>{`--I don’t unit test those at all. Instead, I use functional tests.`}</p>
      <p parentName="blockquote">{`I keep state transitions in pure reducer functions. Easy to test in isolation from the container.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`The display components are just pure functions of their props. Easy to unit test with no mocks.`}</strong></p>
      <p parentName="blockquote">{`The result is great unit coverage + great integration coverage because of the functional tests, and NO MOCKS. ☺"`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@_ericelliott/instead-of-mocking-for-ui-components-i-separate-components-into-display-components-and-container-d2353f0d546b"
      }}>{`https://medium.com/@_ericelliott/instead-of-mocking-for-ui-components-i-separate-components-into-display-components-and-container-d2353f0d546b`}</a></p>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`Eric Elliott`}</p>
    <Image src={ericImg} style={{
      width: '20vw',
      height: '40vh'
    }} mdxType="Image" />
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`HoC - cross-cutting concerns - logging, authorization, (...)`}</em></strong></p>
    <p>{`💡 Can't be replaced with hooks`}</p>
    <blockquote>
      <p parentName="blockquote">{`"The disadvantage to hooks is that if you use them incorrectly, `}<strong parentName="p">{`you could spread logic that could be self contained in one place across all the components in your app`}</strong>{`, opening up lots of opportunities to forget things or to spread duplicated bugs into a thousand places in your app instead of just one.`}</p>
      <p parentName="blockquote">{`Instead of banning all HOCs, you should be aware of which problems are a good use-case for HOCs and which aren’t."`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://medium.com/javascript-scene/do-react-hooks-replace-higher-order-components-hocs-7ae4a08b7b58"
      }}>{`https://medium.com/javascript-scene/do-react-hooks-replace-higher-order-components-hocs-7ae4a08b7b58`}</a></p>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import compose from 'ramda';
import withRedux from './with-redux.js';
import withAuth from './with-auth.js';
import withLogging from './with-logging.js';
import withLayout from './with-layout.js';

const page = compose(
  withRedux,
  withAuth,
  withLogging,
  withLayout('default'),
);

export default page;
`}</code></pre>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import page from '../hocs/page.js';
import MyPageComponent from './my-page-component.js';
export default page(MyPageComponent);
`}</code></pre>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Render Props`}</em></strong></p>
    <p>{`💡 Can be replaced with hooks (not always though, sometimes you need a wrapper)`}</p>
    <p><a parentName="p" {...{
        "href": "https://kentcdodds.com/blog/react-hooks-whats-going-to-happen-to-render-props/"
      }}>{`https://kentcdodds.com/blog/react-hooks-whats-going-to-happen-to-render-props/`}</a></p>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Hooks`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function useFriendStatus(friendID) {
  const [isOnline, setIsOnline] = useState(null);

  function handleStatusChange(status) {
    setIsOnline(status.isOnline);
  }

  useEffect(() => {
    ChatAPI.subscribeToFriendStatus(friendID, handleStatusChange);
    return () => {
      ChatAPI.unsubscribeFromFriendStatus(friendID, handleStatusChange);
    };
  });

  return isOnline;
}
`}</code></pre>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Hooks`}</em></strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function FriendStatus(props) {
  const isOnline = useFriendStatus(props.friend.id);

  if (isOnline === null) {
    return 'Loading...';
  }
  return isOnline ? 'Online' : 'Offline';
}
`}</code></pre>
    <p>{`♥`}</p>
    <hr></hr>
    <h2>{`React patterns are clean by design`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Careful use of React Context`}</em></strong></p>
    <blockquote>
      <p parentName="blockquote">{`"If you only want to avoid passing some props through many levels, component composition is often a simpler solution than context."`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html#when-to-use-context"
      }}>{`https://reactjs.org/docs/context.html#when-to-use-context`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://kentcdodds.com/blog/how-to-use-react-context-effectively"
      }}>{`https://kentcdodds.com/blog/how-to-use-react-context-effectively`}</a></p>
    <hr></hr>
    <h1>{`The Zen of Python 🧘`}</h1>
    <p><a parentName="p" {...{
        "href": "http://thezenofpython.com/"
      }}>{`http://thezenofpython.com/`}</a></p>
    <hr></hr>
    <h1>{`The Zen of React 🧘`}</h1>
    <hr></hr>
    <Image src={rocketImg} style={{
      width: '25vw',
      height: '80vh'
    }} mdxType="Image" />
    <hr></hr>
    <h1>{`🚀 Stick to the Basics`}</h1>
    <p>{`Use ESLint, Prettier, EditorConfig`}</p>
    <hr></hr>
    <h2>{`🚀 Stick to the Basics`}</h2>
    <p>{`Keep utility functions outside the component`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'

const getFullName = () => ... // 👍

const Title = ({user}) => {
  // const getFullName = () => ... 👎
  return (...)
}
`}</code></pre>
    <hr></hr>
    <h2>{`🚀 Stick to the Basics`}</h2>
    <p>{`Destructure props`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Title = ({user, appInfo}) => {
  const {version, hash} = appInfo
  const {nickname} = user

  return (
    <div>
      {getFullName(user)}
      <span>{version} - {hash} - {nickname}</span>
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <h2>{`🚀 Stick to the Basics`}</h2>
    <p>{`No API calls in the component body`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  <button type="button" onClick={() => api.getData()}>Click me please</button>
</div>
`}</code></pre>
    <p>{`👎`}</p>
    <hr></hr>
    <h1>{`🚀 Use component as a primary unit of code reuse`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Show a spinner while the profile is loading
<Suspense fallback={<Spinner />}>
  <ProfilePage />
</Suspense>
`}</code></pre>
    <hr></hr>
    <h2>{`🚀 Use component as a primary unit of code reuse`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  The number is: <br />
  <NumberFormatter format={'0.000'} number={number} />
</div>
`}</code></pre>
    <p>{`👍`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  The number is: <br />
  <span>{numberFormatter(number, '0.000')}</span>
</div>
`}</code></pre>
    <p>{`👎`}</p>
    <hr></hr>
    <h1>{`🚀 Use HoC/Hook/Render Prop as a secondary unit of code reuse`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function useFriendStatus(friendID) {
  const [isOnline, setIsOnline] = useState(null);

  function handleStatusChange(status) {
    setIsOnline(status.isOnline);
  }

  useEffect(() => {
    ChatAPI.subscribeToFriendStatus(friendID, handleStatusChange);
    return () => {
      ChatAPI.unsubscribeFromFriendStatus(friendID, handleStatusChange);
    };
  });

  return isOnline;
}
`}</code></pre>
    <hr></hr>
    <h1>{`🚀 Look for cohesion, avoid coupling`}</h1>
    <p>{`Single Responsibility Rule (SRP)`}</p>
    <p>{`Separate code that supports different actors`}</p>
    <hr></hr>
    <h2>{`🚀 Look for cohesion, avoid coupling`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul"><strong parentName="li">{`A module should have one, and only one, reason to change`}</strong></li>
        <li parentName="ul">{`What does "reason to change" mean?`}</li>
        <li parentName="ul"><strong parentName="li">{`A module should be responsible to one, and only one, user or stakeholder`}</strong></li>
        <li parentName="ul">{`Better.`}</li>
        <li parentName="ul"><strong parentName="li">{`A module should be responsible to one, and only one, actor`}</strong></li>
        <li parentName="ul">{`Best.`}</li>
        <li parentName="ul">{`But who is this "actor"?`}</li>
        <li parentName="ul">{`Another name is `}<strong parentName="li">{`"axis or vector of change"`}</strong>{` ↗.`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Responsibility: formats the number as a string
const numeralJs = format => number => formatNumber(format, number)
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Responsibility: provide the correct markup for the number
// - displays the number in a div
function NumberFormatter({ format, number }) {
  return (
    <div>
      {numeralJs(format)(number)}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function SimpleComponent({ number }) {
  return (
    <div>
      The number is: <br />
      <NumberFormatter format={'0.000'} number={number} />
    </div>
  )
}
// Input:
<SimpleComponent number={0} />

// Output:
// The number is:
// 0.000
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function ComponentWithPicture({ number }) {
  return (
    <div>
      <img src="/img/the-number-is.jpg" /><br />
      <NumberFormatter format={'0'} number={number} />
    </div>
  )
}
// Input:
<ComponentWithPicture number={0} />

// Output:
// <the number is picture>
// 0
`}</code></pre>
    <hr></hr>
    <p>{`🚨 Requirement change: if the number is 0, the NumberFormatter should have `}<inlineCode parentName="p">{`is-zero`}</inlineCode>{` class assigned. 🚨`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`We should ask the important question: `}<strong parentName="li">{`Which actor requested the change`}</strong>{`?`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Solution 1 (wrong):
function NumberFormatter({ format, number }) {
  const className = classnames({ 'is-zero': number === 0 })
  return (
    <div className={className}>
      {numeralJs(format)(number)}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Solution 2 (right):
function SimpleComponent({ number }) {
  return (
    <div>
      The number is: <br />
      <NumberFormatter format={'0.000'} number={number}
        additionalClassName={classnames({ 'is-zero': number === 0 })}
      />
    </div>
  )
}
function NumberFormatter({ format, number, additionalClassName }) {
  return (
    <div className={additionalClassName}>
      {numeralJs(format)(number)}
    </div>
  )
}
NumberFormatter.defaultProps = { additionalClassName: '' }
`}</code></pre>
    <hr></hr>
    <p><strong parentName="p">{`💡 SOLID is useful in context of changes.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://skutecznyprogramista.pl/najwazniejsza-rzecz-w-programowaniu/"
      }}>{`https://skutecznyprogramista.pl/najwazniejsza-rzecz-w-programowaniu/`}</a></p>
    <hr></hr>
    <h1>{`🚀 Make your components/hooks/functions/modules easy to extend`}</h1>
    <p>{`A software artifact should be open for extension but closed for modification.`}</p>
    <hr></hr>
    <p>{`Bertrand Meyer in 1988 (Book: Object Oriented Software Construction):`}</p>
    <blockquote>
      <p parentName="blockquote">{`A module will be said to be open if it is still available for extension.
For example, it should be possible to add fields to the data structures it contains, or new elements to the set of functions it performs.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`A module will be said to be closed if it is available for use by other modules.
This assumes that the module has been given a well-defined, stable description (the interface in the sense of information hiding).`}</p>
    </blockquote>
    <Image src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Bertrand_Meyer_recent.jpg/800px-Bertrand_Meyer_recent.jpg' style={{
      width: '20vw',
      height: '50vh'
    }} mdxType="Image" />
    <hr></hr>
    <p>{`People now:`}</p>
    <Image src={ocpImg} style={{
      width: '20vw',
      height: '50vh'
    }} mdxType="Image" />
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Responsibility: provide the correct markup for the number
function NumberFormatter({ format, number }) {
  return (
    <div>
      {numeralJs(format)(number)}
    </div>
  )
}

function SimpleComponent({ number }) {
  return (
    <div>
      The number is: <br />
      <NumberFormatter format={'0.000'} number={number} />
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <p>{`🚨 Requirement change: if the country is US we need to insert commas 🚨`}</p>
    <p>{`Before: 10000.000
After: 10,000.000`}</p>
    <hr></hr>
    <p>{`Solution:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function NumberFormatter({ format, number }) {
  const adjustedFormat = checkIfUS() ? \`0,\${format}\` : format // 0,0.000

  return (
    <div>
      {numeralJs(adjustedFormat)(number)}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <p>{`🚨 Requirement change: if the `}<inlineCode parentName="p">{`display negatives in parenthesis`}</inlineCode>{` in the settings is set 🚨`}</p>
    <p>{`🚨 then negatives should be displayed in parenthesis 🚨`}</p>
    <p>{`Before: -10000.000`}</p>
    <p>{`After: (10000.000)`}</p>
    <p>{`--`}</p>
    <p>{`Before: -10,000.000`}</p>
    <p>{`After: (10,000.000)`}</p>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Solution (probably breaking OCP, but who knows):
const mapStateToProps = state => ({
  displayNegativesInParenthesis: state.settings.displayNegativesInParenthesis,
})

connect(mapStateToProps)(
  function NumberFormatter({ format, number, displayNegativesInParenthesis }) {
    const locationAdjustedFormat = checkIfUS() ? \`0,\${format}\` : format // 0,0.000

    const settingsAdjustedFormat = displayNegativesInParenthesis
      ? \`(\${locationAdjustedFormat})\` // (0,0.000)
      : locationAdjustedFormat

    return (
      <div>
        {numeralJs(settingsAdjustedFormat)(number)}
      </div>
    )
  }
)
`}</code></pre>
    <hr></hr>
    <p>{`🚨 Requirement change: if we are on the `}<inlineCode parentName="p">{`/shop/dinosaur-toys`}</inlineCode>{` route we should paint the numbers green 🚨`}</p>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Solution (probably breaking OCP, but who knows):
const mapStateToProps = state => ({
  displayNegativesInParenthesis: state.settings.displayNegativesInParenthesis,
})

connect(mapStateToProps)(
  withRouter(
    function NumberFormatter({
      format,
      number,
      displayNegativesInParenthesis,
      location, // <- this is new
    }) {
      const locationAdjustedFormat = checkIfUS() ? \`0,\${format}\` : format // 0,0.000

      const settingsAdjustedFormat = displayNegativesInParenthesis
        ? \`(\${locationAdjustedFormat})\` // (0,0.000)
        : locationAdjustedFormat

      const className = classnames({
        red: location.pathname.includes('dinosaur-toys')
      })

      return (
        <div className={className}>
          {numeralJs(settingsAdjustedFormat)(number)}
        </div>
      )
    }
  )
)
`}</code></pre>
    <hr></hr>
    <p>{`What if?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// adjustFormatting is the key here.
// Thanks to that the NumberFormatter is closed for modification (at least for now)
// and open for extension - just pass the \`adjustFormatting\` function however you like and you are fine
function NumberFormatter({
  format,
  number,
  adjustFormatting,
  className,
}) {
  const adjustedFormat = adjustFormatting(format)
  return (
    <div className={className}>
      {numeralJs(adjustedFormat)(number)}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <h1>{`🚀 And do it only when you need it`}</h1>
    <p>{`💡 `}<strong parentName="p">{`OCP is not a rule you can break.`}</strong></p>
    <p>{`💡 It's all about flexibility and OCP is a process`}</p>
    <hr></hr>
    <h2>{`🚀 And do it only when you need it`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Low coupling and protection against variations is not motivated in all areas.`}</p>
      <p parentName="blockquote">{`You must pick your battles in design, be they at the macro-architectural level or the humble instance field.`}</p>
      <p parentName="blockquote">{`A good designer can identify the likely points of instability or variation and apply PV to those points but not others.`}</p>
      <p parentName="blockquote">{`Otherwise, effort is wasted and complexity may arise (and with it, the chance for defects).`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`🚀 And do it only when you need it`}</h2>
    <p>{`"Fool me once, shame on you; fool me twice, shame on me"`}</p>
    <hr></hr>
    <h1>{`🚀 Don't surprise people`}</h1>
    <ol>
      <li parentName="ol">{`Special cases aren't special enough to break the rules. (The Zen of Python)`}</li>
      <li parentName="ol">{`Although practicality beats purity. (also The Zen of Python)`}</li>
    </ol>
    <hr></hr>
    <h2>{`🚀 Don't surprise people`}</h2>
    <p>{`Liskov Substitution Principle (LSP)`}</p>
    <blockquote>
      <p parentName="blockquote">{`"If S is a subtype of T, then objects of type T in a program may be replaced with objects of type S without altering any of the desirable properties of that program"`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{` A simple violation of substitutability can cause a system's architecture to be polluted with a significant amount of extra mechanisms`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`🚀 Don't surprise people`}</h2>
    <p>{`Smell: Explicit checking for type`}</p>
    <ul>
      <li parentName="ul">{`is a?`}</li>
      <li parentName="ul">{`kind of?`}</li>
      <li parentName="ul">{`case`}</li>
      <li parentName="ul">{`typeof?`}</li>
      <li parentName="ul">{`instanceof?`}</li>
    </ul>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function SomeComponent() {
  return (
    <PictureDataProvider>
      {pictureData => (
        <>
          <Topbar>
            <ProfilePicture pictureData={pictureData} />
          </Topbar>

          <LeftBar>
            <Avatar pictureData={pictureData} />
          </LeftBar>

          <Menu>
            <NavigationPicture pictureData={pictureData} />
          </Menu>
        </>
      )}
    </PictureDataProvider>
  )
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`ProfilePicture.propTypes = {
  pictureData: PropTypes.shape({
    url: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  })
}
Avatar.propTypes = {
  pictureData: PropTypes.shape({
    url: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  })
}
NavigationPicture.propTypes = {
  pictureData: PropTypes.shape({
    url: PropTypes.shape({ // <- WTF
      pathName: PropTypes.string.isRequired,
      queryString: PropTypes.string,
    }).isRequired,
    size: PropTypes.number.isRequired,
  })
}
`}</code></pre>
    <hr></hr>
    <h1>{`🚀 Avoid depending on things you don't use`}</h1>
    <p>{`Decompose functions`}</p>
    <p>{`Segregate functionalities`}</p>
    <hr></hr>
    <h2>{`🚀 Avoid depending on things you don't use`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`The lesson here is that depending on something that carries baggage that you don't need can cause you troubles you didn't expect.`}</strong></p>
    </blockquote>
    <p>{`Dynamically typed languages are more flexible by design`}</p>
    <p>{`Examples:`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`overloaded module/package`}</li>
        <li parentName="ul">{`overloaded component that does too much`}</li>
        <li parentName="ul">{`bloated hook`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Picture({
  pictureData,
  onClick,
  onDoubleClick,
  onRouteChange,
  borderOptions,
  gravatarSettings,
  sticky,
  filter,
  circle,
  wrapperComponent,
}) {
  // a lot of logic here
  // render ...
}
`}</code></pre>
    <hr></hr>
    <p>{`Solution:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function ProfilePicture({
  pictureData,
  onClick,
  onDoubleClick,
  gravatarSettings,
  wrapperComponent,
}) {
  // some logic here
  return <div>
    {/* maybe some wrappers and event hanlers here */}
    <Picture pictureData={pictureData} />
  </div>
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Avatar({
  pictureData,
  borderOptions,
  sticky,
  filter,
  circle,
}) {
  // some logic here
  return <div>
    {/* maybe some wrappers and event hanlers here */}
    <Picture pictureData={pictureData} />
  </div>
}
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function NavigationPicture({
  pictureData,
  onRouteChange,
  wrapperComponent,
}) {
  // some logic here
  return <div>
    {/* maybe some wrappers and event hanlers here */}
    <Picture pictureData={pictureData} />
  </div>
}
`}</code></pre>
    <hr></hr>
    <h1>{`🚀 Wrap libraries (create abstractions)`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Stable software architectures are those that `}<strong parentName="p">{`avoid depending on volatile concretions`}</strong>{`, and that favor the use of stable abstract interfaces.`}</p>
      <p parentName="blockquote">{`It is the volatile concrete elements of our system that we want to avoid depending on.`}</p>
      <p parentName="blockquote">{`Those are the modules that we are actively developing, and that are `}<strong parentName="p">{`undergoing frequent change`}</strong>{`.`}</p>
      <p parentName="blockquote">{`~Clean Architecture`}</p>
    </blockquote>
    <hr></hr>
    <p>{`Volatile?`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`React?`}</li>
        <li parentName="ul">{`Redux?`}</li>
        <li parentName="ul">{`JS Date?`}</li>
        <li parentName="ul">{`JS Number?`}</li>
        <li parentName="ul">{`version 0.13.2 of your favorite library?`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import numeralJs from 'numeraljs'

function SimpleComponent({ number }) {
  return (
    <div>
      The number is: <br />
      {numeralJs('0.000')(number)}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <p>{`Better:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import numeralJs from 'numeraljs'

function NumberFormatter({ format, number }) {
  return (
    <div>
      {numeralJs(format)(number)}
    </div>
  )
}

// another file
function SimpleComponent({ number }) {
  return (
    <div>
      The number is: <br />
      <NumberFormatter format={'0.000'} number={number} /> {/* 👈 stable interface */}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <h1>{`🚀 SLAP your components`}</h1>
    <p>{`Keep the code within a given segment/block at the same level of abstraction.`}</p>
    <p>{`Don't do this:`}</p>
    <p><a parentName="p" {...{
        "href": "https://gist.github.com/kjendrzyca/90b42319ad23b5d0adcb8d694aa26b48"
      }}>{`https://gist.github.com/kjendrzyca/90b42319ad23b5d0adcb8d694aa26b48`}</a></p>
    <hr></hr>
    <h1>{`🚀 Avoid hasty abstractions, optimize for change first`}</h1>
    <p>{`Developer's favorite rule: DRY 🏜`}</p>
    <Steps mdxType="Steps">
      <p>{`  It's the easiest one.`}</p>
      <p>{`  But not really.`}</p>
    </Steps>
    <hr></hr>
    <p>{`Remember this one?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function NumberFormatter({ format, number }) {
  const className = classnames({ 'is-zero': number === 0 })
  return (
    <div className={className}>
      {numeralJs(format)(number)}
    </div>
  )
}
`}</code></pre>
    <hr></hr>
    <h2>{`AHA (aka WET, aka The Rule of Three)`}</h2>
    <ul>
      <li parentName="ul">{`The Rule of Three`}</li>
      <li parentName="ul">{`WET - Write Everything Twice`}</li>
    </ul>
    <hr></hr>
    <h3>{`The Rule of Three`}</h3>
    <ol>
      <li parentName="ol">{`Write a piece of code.`}</li>
      <li parentName="ol">{`Write the same piece of code again. Resist the urge to generalize.`}</li>
      <li parentName="ol">{`Write the same piece of code again. Now you are allowed to consider generalizing it.`}</li>
    </ol>
    <hr></hr>
    <h3>{`WET - Write Everything Twice`}</h3>
    <blockquote>
      <p parentName="blockquote">{`"You can ask yourself "Haven't I written this before?" two times, but never three." ~Conlin Durbin`}</p>
    </blockquote>
    <hr></hr>
    <h3>{`AHA - Avoid Hasty Abstractions`}</h3>
    <blockquote>
      <p parentName="blockquote">{`The more samples you have of duplicated code, the better you understand what is truly duplicated, and what varies.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Optimize for change first`}</h2>
    <p>{`"We should be mindful of the fact that we don't really know what requirements will be placed upon our code in the future" ~Kent C. Dodds`}</p>
    <p><a parentName="p" {...{
        "href": "https://skutecznyprogramista.pl/jak-hackowac-brak-doswiadczenia/"
      }}>{`https://skutecznyprogramista.pl/jak-hackowac-brak-doswiadczenia/`}</a></p>
    <hr></hr>
    <h1>{`🚀 Simplify testing by injecting problematic stuff (use container components)`}</h1>
    <blockquote>
      <p parentName="blockquote">{`I don't recommend unit testing stateful components, or components with side-effects.`}</p>
      <p parentName="blockquote">{`(...)`}</p>
      <p parentName="blockquote">{`A great alternative is to encapsulate side-effects and state management in container components, and then pass state into pure components as props.`}</p>
      <p parentName="blockquote">{`Unit test the pure components and use functional tests to ensure that the complete UX flow works in real browsers from the user's perspective.`}</p>
    </blockquote>
    <Image src={ericImg} style={{
      width: '20vw',
      height: '40vh'
    }} mdxType="Image" />
    <p><a parentName="p" {...{
        "href": "https://github.com/ericelliott/riteway"
      }}>{`https://github.com/ericelliott/riteway`}</a></p>
    <hr></hr>
    <h1>{`🚀 Use feature folders`}</h1>
    <p>{`I call it "The Clean Folder Structure".`}</p>
    <hr></hr>
    <h2>{`The MERN Way`}</h2>
    <pre><code parentName="pre" {...{}}>{`/components
/containers
/common
/utils
/tests
App.jsx
`}</code></pre>
    <hr></hr>
    <h2>{`The MERN Way`}</h2>
    <Image src={doesitscaleImg} style={{
      width: '18vw',
      height: '40vh'
    }} mdxType="Image" />
    <hr></hr>
    <h2>{`The MERN Way`}</h2>
    <Image src={itDoesntImg} style={{
      width: '18vw',
      height: '40vh'
    }} mdxType="Image" />
    <hr></hr>
    <pre><code parentName="pre" {...{}}>{`/components
lorem.js
ipsum.js
dolor.js
sit.js
amet.js
consectetur.js
adipiscing.js
elit.js
nunc.js
feugiat.js
userProfile.js
non.js
erat.js
placerat.js
fermentum.js
lacus.js
volutpat.js
Interdum.js
malesuada.js
fames.js
ante.js
primis.js
faucibus.js
suspendisse.js
potenti.js
Integer.js
lacinia.js
mauris.js
Nunc.js
lobortis.js
scelerisque.js
lacinia.js
sapien.js
pellentesque.js
commodo.js
tortor.js
luctus.js
magna.js
convallis.js
commodo.js
sed.js
magna.js
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{}}>{`/containers
lorem.container.js
ipsum.container.js
dolor.container.js
sit.container.js
amet.container.js
consectetur.container.js
adipiscing.container.js
elit.container.js
nunc.container.js
userProfile.container.js
lacus.container.js
non.container.js
erat.container.js
placerat.container.js
fermentum.container.js
lacus.container.js
volutpat.container.js
Interdum.container.js
malesuada.container.js
fames.container.js
ante.container.js
primis.container.js
faucibus.container.js
suspendisse.container.js
potenti.container.js
Integer.container.js
lacinia.container.js
mauris.container.js
Nunc.container.js
lobortis.container.js
scelerisque.container.js
lacinia.container.js
sapien.container.js
pellentesque.container.js
commodo.container.js
tortor.container.js
luctus.container.js
magna.container.js
convallis.container.js
commodo.container.js
sed.container.js
magna.container.js
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{}}>{`/hoc
lorem.hoc.js
ipsum.hoc.js
dolor.hoc.js
sit.hoc.js
amet.hoc.js
consectetur.hoc.js
adipiscing.hoc.js
elit.hoc.js
nunc.hoc.js
feugiat.hoc.js
lacus.hoc.js
non.hoc.js
erat.hoc.js
placerat.hoc.js
fermentum.hoc.js
userProfile.hoc.js
volutpat.hoc.js
Interdum.hoc.js
malesuada.hoc.js
fames.hoc.js
ante.hoc.js
primis.hoc.js
faucibus.hoc.js
suspendisse.hoc.js
potenti.hoc.js
Integer.hoc.js
lacinia.hoc.js
mauris.hoc.js
Nunc.hoc.js
lobortis.hoc.js
scelerisque.hoc.js
lacinia.hoc.js
sapien.hoc.js
pellentesque.hoc.js
commodo.hoc.js
tortor.hoc.js
luctus.hoc.js
magna.hoc.js
convallis.hoc.js
commodo.hoc.js
sed.hoc.js
magna.hoc.js
`}</code></pre>
    <hr></hr>
    <p>{`The folder structure from tutorials is not a representation of the real world.`}</p>
    <Steps mdxType="Steps">
      <p>{`Yet, it’s copy-pasted to production code and our clients pay for it.`}</p>
      <p>{`🤷‍♂️`}</p>
    </Steps>
    <hr></hr>
    <h2>{`React is clean by design, remember?`}</h2>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Segregate responsibilities by components, not technologies`}</em></strong></p>
    <p>{`🚀 `}<strong parentName="p"><em parentName="strong">{`Divide reponsibilities based on what you see on the screen`}</em></strong></p>
    <Image src={reactcomponentsuiImg} style={{
      width: '30vw',
      height: '40vh'
    }} mdxType="Image" />
    <hr></hr>
    <pre><code parentName="pre" {...{}}>{`/userSettingsPage
  /store
    actions.js
    api.js
    reducer.js
    selectors.js
  profileForm.component.js
  userSettingsPage.container.js

/topNavbar
  /store
    actions.js
    api.js
    reducer.js
    selectors.js
  topNavbar.container.js
  avatar.component.js

/lorem
  ..
`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{}}>{`/userSettingsPage
  ..
/topNavbar
  ..
/lorem
  ..
/ipsum
  ..
/dolor
  ..
/sit
  ..
/amet
  ..
/consectetur
  ..

`}</code></pre>
    <hr></hr>
    <pre><code parentName="pre" {...{}}>{`/shared
  /displayComponents
    ..
  /textFormatters
    ..
  /formComponents
    input.js
    textarea.js
  /store
    userProfile.reducer.js
    userProfile.selectors.js
    applicationSettings.reducer.js
    applicationSettings.selectors.js
`}</code></pre>
    <hr></hr>
    <p>{`Kind of similar idea:`}</p>
    <p><a parentName="p" {...{
        "href": "https://bradfrost.com/blog/post/atomic-web-design/"
      }}>{`https://bradfrost.com/blog/post/atomic-web-design/`}</a></p>
    <hr></hr>
    <Image src={finishingTouchImg} style={{
      width: '40vw',
      height: '70vh'
    }} mdxType="Image" />
    <hr></hr>
    <h1>{`The Most Important Thing to Remember`}</h1>
    <Steps mdxType="Steps">
      <p><strong parentName="p">{`🚀 It's all about well composed tree of (reusable) components and explicit data flow between them. 🚀`}</strong></p>
    </Steps>
    <hr></hr>
    <h1>{`React is clean by design`}</h1>
    <ul>
      <li parentName="ul">{`Segregate responsibilities by components, not technologies`}</li>
      <li parentName="ul">{`Divide reponsibilities based on what you see on the screen`}</li>
      <li parentName="ul">{`Explicit dependencies - props`}</li>
      <li parentName="ul">{`Explicit interface - prop types`}</li>
      <li parentName="ul">{`One way data flow`}</li>
      <li parentName="ul">{`Explicit state updates`}</li>
      <li parentName="ul">{`Explicit event handlers`}</li>
      <li parentName="ul">{`No inheritance, (functional) composition instead`}</li>
      <li parentName="ul">{`Containers and Presentational Components`}</li>
      <li parentName="ul">{`HoC - cross-cutting concerns - logging, authorization, (...)`}</li>
      <li parentName="ul">{`Render Props`}</li>
      <li parentName="ul">{`Hooks`}</li>
      <li parentName="ul">{`Careful use of React Context`}</li>
    </ul>
    <hr></hr>
    <h1>{`The Zen of React`}</h1>
    <ol>
      <li parentName="ol">{`Stick to the Basics`}</li>
      <li parentName="ol">{`Use component as a primary unit of code reuse`}</li>
      <li parentName="ol">{`Use HoC/Hook/Render Prop as a secondary unit of code reuse`}</li>
      <li parentName="ol">{`Look for cohesion, avoid coupling`}</li>
      <li parentName="ol">{`Make your components/hooks/functions/modules easy to extend`}</li>
      <li parentName="ol">{`And do it only when you need it`}</li>
      <li parentName="ol">{`Don't surprise people`}</li>
      <li parentName="ol">{`Avoid depending on things you don't use`}</li>
      <li parentName="ol">{`Wrap libraries (create abstractions)`}</li>
      <li parentName="ol">{`SLAP your components`}</li>
      <li parentName="ol">{`Avoid hasty abstractions, optimize for change first`}</li>
      <li parentName="ol">{`Simplify testing by injecting problematic stuff (use container components)`}</li>
      <li parentName="ol">{`Use feature folders`}</li>
    </ol>
    <hr></hr>
    <h1>{`Links and stuff`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://thezenofreact.com"
          }}>{`thezenofreact.com`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.amazon.com/Clean-Code-Handbook-Software-Craftsmanship-ebook-dp-B001GSTOAM/dp/B001GSTOAM/ref=mt_other?_encoding=UTF8&me=&qid="
          }}>{`Clean Code`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.amazon.com/Clean-Architecture-Craftsmans-Software-Structure-ebook-dp-B075LRM681/dp/B075LRM681/ref=mt_other?_encoding=UTF8&me=&qid="
          }}>{`Clean Architecture`}</a></p>
      </li>
    </ul>
    <hr></hr>
    <Image src={thanksImg} style={{
      width: '40vw',
      height: '65vh'
    }} mdxType="Image" />
    <hr></hr>
    <Image src={spImg} style={{
      width: '60vw',
      height: '70vh'
    }} mdxType="Image" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;